import React from "react";
import Footer from "../components/Footer";
import { Box } from "@mui/material";

export default function WhoWeAre() {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: "1", backgroundColor: "#DDDDDD" }} pt={20}>
        <Box
          id="quienesSomos"
          sx={{
            width: { xs: "75%", md: "40%" },
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <h3
              style={{
                color: "#1555fd",
                fontWeight: "bold",
                width: "fit-content",
                borderBottom: "3.5px solid #296ce0",
                lineHeight: 1,
              }}
            >
              QUIÉNES SOMOS?
            </h3>
          </div>
          <p style={{ margin: 0, fontSize: 18, textAlign: "justify" }}>
            En Resuelve Colombia, somos una empresa dedicada a ofrecer soluciones de mensajería rápidas y seguras para pequeñas empresas y particulares en todo el país.
            <br />
            <br />
            Nuestro compromiso es hacer que el envío de paquetes y correo sea una experiencia accesible, confiable y eficiente.
            <br />
            <br />
            Contamos con una plataforma virtual de fácil uso y una app móvil que permiten a nuestros clientes gestionar sus envíos de forma ágil y desde cualquier lugar.
            <br />
            <br />
            Además, contamos con una red activa de más de 100 domiciliarios que garantizan la entrega oportuna de cada pedido.
            <br />
            <br />
            Para facilitar el acceso a nuestros servicios, ofrecemos diversos métodos de pago, como pagos por PSE y recaudos a través de Bancolombia, brindando a nuestros usuarios flexibilidad y comodidad en sus transacciones.
            <br />
            <br />
            Nos proyectamos como una empresa en constante crecimiento, conectando a comunidades y empresas de todo el país, ofreciendo soluciones de mensajería accesibles e inclusivas para quienes más lo necesitan.
          </p>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </Box>
  );
}
