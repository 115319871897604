import React from "react";
import { Image } from "react-bootstrap";
import { Card, CardContent, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Footer from "../components/Footer";
import FrequentlyQuestions from "../components/FrequentlyQuestions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Home() {
  const theme = useTheme();
  // Variables para controlar el tamaño de la imagen
  const isAbove1000px = useMediaQuery("(min-width:1000px)");
  const isBelow1250px = useMediaQuery("(max-width:1250px)");
  const isBelow1628px = useMediaQuery("(max-width:1628px)");
  const isBelow833px = useMediaQuery("(max-width:833px)");

  // Variable para controlar el tamaño de la imagen en el media query md
  const mediaMd = useMediaQuery(theme.breakpoints.up("md"));


  return (
    <>
      <Box sx={{ backgroundColor: "#DDDDDD" }}>
        <Grid
          container
          sx={{
            background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
            height: {
              xs: isBelow833px ? 900 : 555,
              md: isAbove1000px ? 700 : 635,
              lg: 800,
              xl: 1130,
            },
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
          px={{ xs: 2, md: 5 }}
          colomns={12}
        >
          <Grid
            size={{ md: 5 }}
            order={isBelow833px ? 2 : 1}
            sx={{
              height: { xs: isBelow833px ? "100%" : "inital" },
              marginTop: { xs: isBelow833px ? "30px" : 0 },
            }}
          >
            <Image
              src={require("../assets/image/portadaHome.png")}
              alt="Portada"
              layout="fill"
              style={{
                width: mediaMd ? "100%" : "319px",
                height: mediaMd ? 690 : "319px",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid
            size={{ md: 7 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
            mt={{ xs: isBelow833px ? "90px" : 0, md: 0, xl: -24 }}
            order={isBelow833px ? 1 : 2}
          >
            <Image
              src={require("../assets/image/logo.png")}
              alt="Logo"
              layout="fill"
              style={{ width: mediaMd ? "48%" : "280px", objectFit: "contain" }}
            />
            <Typography component="div" mt={-2}>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: {
                    xs: "2.3rem",
                    sm: isAbove1000px ? "3rem" : "2.75rem",
                    lg: isBelow1250px ? "3.55rem" : "3.70rem",
                    xl: isBelow1628px ? "4.4rem" : "4.75rem",
                  },
                  fontWeight: "700",
                  lineHeight: 0.9,
                  letterSpacing: -3.5,
                }}
              >
                ¿Necesitas realizar envíos?
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: {
                    xs: "2.2rem",
                    sm: isAbove1000px ? "3rem" : "2.75rem",
                    lg: isBelow1250px ? "3.55rem" : "3.70rem",
                    xl: "4.1rem",
                  },
                  fontWeight: "500",
                  lineHeight: 0.9,
                  letterSpacing: -3.5,
                }}
              >
                Nosotros te resolvemos
              </Typography>
            </Typography>
          </Grid>
        </Grid>

        <Card
          sx={{
            backgroundColor: "white",
            width: "94%",
            margin: "0 auto",
            marginTop: { xs: isBelow833px ? -5 : -10, xl: -25 },
            borderRadius: 2.8,
          }}
        >
          <CardContent
            sx={{
              backgroundColor: "white",
              margin: "0 auto",
            }}
          >
            <Typography
              component="div"
              style={{
                margin: "0 auto",
                textAlign: "justify",
                marginTop: isBelow833px ? 20 :77,
              }}
              sx={{
                width: { md: 600 },
                paddingLeft: { xs: 2, md: 0 },
                paddingRight: { xs: 2, md: 0 },
              }}
            >
              <Box sx={{ color: "#797979", fontSize: isBelow833px ? 20 : 25, lineHeight: 1.2 }}>
                Con <b>Resuelve</b> puedes ralizar envío de tus paquetes a donde
                desees! si eres persona natural o empresa, ponemos a tu
                disposición un equipo que te brindará servicios al momento que
                desees!
              </Box>
            </Typography>
            <Grid container style={{ marginTop: isBelow833px ? "30px" : "52px" }} columns={12}>
              <Grid
                item
                size={{ xs: 12, md: 4 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Image
                  src={require("../assets/image/paquete.png")}
                  alt="paquete"
                  style={{ width: 200 }}
                />
                <Box>
                  <Box
                    style={{
                      color: "#006BF1",
                      fontWeight: "bold",
                      fontSize: isBelow833px ? 20 : 23,
                    }}
                  >
                    Paquete sencillo
                  </Box>
                  <Box
                    style={{
                      marginTop: 5,
                      color: "#797979",
                      fontWeight: 400,
                      fontSize: isBelow833px ? 17 : 20,
                    }}
                    px={{ md: 5, lg: 15 }}
                  >
                    Perfecto para{" "}
                    <span style={{ fontWeight: 700 }}>personas naturales.</span>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 4 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                mt={{ xs: 7, md: 0 }}
              >
                <Image
                  src={require("../assets/image/conjuntoPaquetes.png")}
                  alt="conjuntoPaquetes"
                  style={{ width: 200 }}
                />
                <Box>
                  <Box
                    style={{
                      color: "#006BF1",
                      fontWeight: "bold",
                      fontSize: isBelow833px ? 20 : 23,
                    }}
                  >
                    Paquetes medianos
                  </Box>
                  <Box
                    style={{
                      marginTop: 5,
                      color: "#797979",
                      fontWeight: 400,
                      fontSize: isBelow833px ? 17 : 20,
                    }}
                    px={{ md: 5, lg: 15 }}
                  >
                    Mypimes que quieran{" "}
                    <span style={{ fontWeight: 700 }}>ampliar </span> su
                    mercado.
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 4 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
                mt={{ xs: 7, md: 0 }}
              >
                <Image
                  src={require("../assets/image/paquetes.png")}
                  alt="paquetes"
                  style={{ width: 200 }}
                />
                <Box>
                  <Box
                    style={{
                      color: "#006BF1",
                      fontWeight: "bold",
                      fontSize: isBelow833px ? 20 : 23,
                    }}
                  >
                    Paquetes grandes
                  </Box>
                  <Box
                    style={{
                      marginTop: 5,
                      color: "#797979",
                      fontWeight: 400,
                      fontSize: isBelow833px ? 17 : 20,
                    }}
                    px={{ md: 5, lg: 15 }}
                  >
                    Empresas con mayor capacidad{" "}
                    <span style={{ fontWeight: 700 }}>operativa.</span>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginTop: "50px",
                alignItems: "center",
                textAlign: "center",
              }}
              colums={12}
            >
              <Grid
                size={{ md: 5 }}
                pl={{ md: isAbove1000px ? 5 : 0 }}
                style={{ margin: mediaMd ? "0" : "0 auto" }}
              >
                <Image
                  src={require("../assets/image/celular.png")}
                  alt="views"
                  layout="fill"
                  style={{ width: mediaMd ? 366 : 200, objectFit: "contain" }}
                />
              </Grid>
              <Grid size={{ md: 7 }} style={{ margin: mediaMd ? "0" : "0 auto" }}>
                <Typography component="div">
                  <Box
                    style={{
                      textAlign: "center",
                      color: "#006BF1",
                      fontWeight: "bold",
                      fontSize: isBelow833px ? 20 : 23,
                    }}
                  >
                    Todo al alcance de tu App
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: isBelow833px ? 17 : 20,
                      color: "#797979",
                      paddingLeft: { xs: 2, md: 0 },
                      paddingRight: { xs: 2, md: 0 },
                    }}
                    px={{ md: 10, lg: 25 }}
                  >
                    Registrate con nosotros y controla todo tu proceso desde el
                    celular
                  </Box>
                  <Typography
                    style={{
                      marginTop: isBelow833px ? "40px":"76px",
                      color: "#797979",
                      textAlign: "left",
                      fontSize: isBelow833px ? 20 : 27,
                    }}
                  
                  >
                    <ul>
                      <li style={{ color: "gray", marginBottom: 10 }}>
                        Control de tus pedidos a <b>tiempo real</b>
                      </li>
                      <li style={{ color: "gray", marginBottom: 10 }}>
                        Control de <b>recaudos</b> y entregas de pedidos
                      </li>
                      <li style={{ color: "gray", marginBottom: 10 }}>
                        Estadísticas de envíos
                      </li>
                      <li style={{ color: "gray", marginBottom: 10 }}>
                        Bases de datos de clientes e historiales de procesos
                      </li>
                    </ul>
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <FrequentlyQuestions />
      <Footer />    
      </>
  );
}
