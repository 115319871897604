import { Image, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function NavBar() {
  const location = useLocation();

  const isAbove991px = useMediaQuery("(max-width:991px)");

  const isActive = (path) => location.pathname === path;

  const [expanded, setExpanded] = useState(false); 

  const handleToggle = () => setExpanded(!expanded);
  const closeNavbar = () => setExpanded(false); 

  return (
    <Navbar
      expand="lg"
      id="customNavBar"
      expanded={expanded}
      style={{
        backgroundColor: "#1555fd",
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        padding: isAbove991px ? "0 40px 0 40px" : "0 80px 0 60px",
      }}
    >
      <Navbar.Brand>
        <Link to="/">
          <Image
            src={require("../assets/image/logo.png")}
            width="95"
            alt="Logo de resuelve colombia"
          />
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
      <Navbar.Collapse id="basic-navbar-nav" style={{fontSize:20}}>
        <Nav
          className="me-auto"
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "90%",
          }}
        >
          <Nav.Link
            as={Link}
            to="/"
            className={isActive("/") ? "active-link" : ""}
            onClick={closeNavbar}
          >
            Inicio
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/subscriptions"
            className={isActive("/subscriptions") ? "active-link" : ""}
            onClick={closeNavbar}
          >
            Suscripciones
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/register"
            className={isActive("/register") ? "active-link" : ""}
            onClick={closeNavbar}
          >
            Registrate
          </Nav.Link>
          <NavDropdown title="Nosotros" id="basic-nav-dropdown">
            <NavDropdown.Item
              as={Link}
              to="/organizational-culture"
              className={
                isActive("/organizational-culture") ? "active-link" : ""
              }
              onClick={closeNavbar}
            >
              Cultura Organizacional
            </NavDropdown.Item>
            <NavDropdown.Item
              as={Link}
              to="/who-we-are"
              className={isActive("/who-we-are") ? "active-link" : ""}
              onClick={closeNavbar}
            >
              Quienes Somos
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link
            as={Link}
            to="/pay-order"
            className={isActive("/pay-order") ? "active-link" : ""}
            onClick={closeNavbar}
          >
            Pagar pedido
          </Nav.Link>
        </Nav>
        <Link
          to="/login"
          className="btnNavBar btn btn-primary"
          onClick={closeNavbar}
        >
          Iniciar sesión
        </Link>
      </Navbar.Collapse>
    </Navbar>
  );
}
