import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Footer(props) {
  const space = useMediaQuery("(max-width:411px)");
  const space30px = useMediaQuery("(max-width:380px)");

  return (
    <footer style={{ backgroundColor: "#DDDDDD", paddingTop: 50 }}>
      {/* SECCION FINAL */}
      <Row style={{ width: "90%", margin: "100px auto 50px auto" }}>
        <Col id="nosotros">
          <h3
            style={{
              color: "#1555fd",
              fontWeight: "bold",
              width: "fit-content",
              borderBottom: "3.5px solid #296ce0",
              paddingBottom: 5,
            }}
          >
            NOSOTROS
          </h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              marginTop: 30,
              flexDirection: space30px ? "column" : "row",
            }}
          >
            <Image
              src={require("../assets/image/wpp.png")}
              width="30"
              alt="WhatsApp"
            />
            <h5 style={{ margin: 0, marginTop: space30px ? 2 : 0 }}>
              (300) 161 - 3748
            </h5>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              marginTop: space30px ? 35 : 10,
              flexDirection: space30px ? "column" : "row",
            }}
          >
            <Image
              src={require("../assets/image/mail.png")}
              width="30"
              alt="Mail"
            />
            <h5 style={{ margin: 0, marginTop: space30px ? 2 : 0 }}>
              resuelve@resuelvecolombia.co
            </h5>
          </div>
        </Col>
        <Col id="logo" className="d-flex justify-content-center">
          <Image
            src={require("../assets/image/logoAzul.png")}
            width="300"
            height="70%"
            alt="Logo azul de resuelve colombia"
            style={{ objectFit: "contain" }}
          />
        </Col>
        <Col
          id="mediosDePago"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h3
            style={{
              color: "#1555fd",
              fontWeight: "bold",
              width: "fit-content",
              borderBottom: "3.5px solid #296ce0",
              paddingBottom: 5,
            }}
          >
            MEDIOS DE PAGO
          </h3>
          <div
            style={{
              display: "flex",
              marginTop: 30,
              width: "100%",
              justifyContent: "center",
              gap: 30,
              alignItems: "center",
            }}
          >
            <Image
              src={require("../assets/image/nequi.png")}
              style={{ objectFit: "contain", width: 120, height: 40 }}
              alt="Logo Nequi"
            />

            <Image
              src={require("../assets/image/bancolombia.png")}
              style={{ objectFit: "contain", width: 80 }}
              alt="Logo Bancolombia"
            />

            <Image
              src={require("../assets/image/pse.png")}
              style={{ objectFit: "contain", width: 80 }}
              alt="Logo PSE"
            />
          </div>
        </Col>
      </Row>
      <Row
        id="rightReserved"
        style={{
          backgroundColor: "#296ce0",
          textAlign: "center",
          padding: "10px 0",
          color: "white",
          fontWeight: "bold",
        }}
      >
        <span>
          &copy; Todos los derechos reservados{" "}
          <br style={{ display: space ? "block" : "none" }} /> RESUELVE 2021
        </span>
      </Row>
    </footer>
  );
}
