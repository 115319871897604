import React, { useState } from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import truckSVG from "../assets/image/truck.svg";
import Swal from "sweetalert2";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function PayOrder() {
  const [orderNumber, setOrderNumber] = useState("");
  const isBelow833px = useMediaQuery("(max-width:833px)");

  //una alerta que diga "El pedido no se encuentra registrado"
  const handlePayOrder = () => {
    Swal.fire({
      title: "Error!",
      text: "El pedido no se encuentra registrado",
      icon: "error",
      confirmButtonText: "Cerrar",
    });
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
        height: "100vh",
      }}
      style={{
        padding: "90px 20px 0 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        id="principalContainer"
        sx={{
          position: "relative",
          // top: "50%",
          width: { xs: "100%", md: 650 },
          // transform: "translateY(-50%)",
          textAlign: "center",
          margin: "0 auto",
          padding: { xs: "40px 28px", sm: "40px 80px" },
          backgroundColor: "white",
          borderRadius: 5,
        }}
      >
        <Box
          id="title"
          sx={{
            height: 59,
            width: { xs: 205, sm: 320 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#11ddb3",
            color: "white",
            borderRadius: 2,
            margin: "0 auto",
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h4 style={{ margin: 0, fontWeight: "bold" }}>Pagar pedido</h4>
        </Box>
        <Box id="body" mt={2.5}>
          <svg
            style={{
              width: 90,
              height: 90,
              fill: "#1555fd",
              margin: "20px auto",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
          </svg>
          <InputGroup className="my-5">
            <InputGroup.Text id="basic-addon1">
              <Image
                src={truckSVG}
                alt="logo"
                style={{ width: 30, height: 30, transform: "rotateY(180deg)" }}
              />
            </InputGroup.Text>
            <Form.Control
              placeholder="Ingresa tu número de pedido"
              aria-label="Ingresa tu número de pedido"
              aria-describedby="basic-addon1"
            />
          </InputGroup>
          <button
            className="btn"
            style={{
              backgroundColor: "#1555fd",
              color: "white",
              borderRadius: 8,
              fontWeight: "bold",
              width: isBelow833px ? 150 : 230,
              marginTop: 20,
              padding: "12px 0",
              fontSize: 20,
            }}
            onClick={() => handlePayOrder()}
          >
            INGRESAR
          </button>
        </Box>
      </Box>
    </Box>
  );
}
