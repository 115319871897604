import React, { useState } from "react";
import { Col, Form, Image, InputGroup, Row } from "react-bootstrap";
import letterSVG from "../assets/image/letter.svg";
import Lock from "../assets/image/lock.svg";
import Swal from "sweetalert2";
import { Card, CardContent, Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Footer from "../components/Footer";
import FrequentlyQuestions from "../components/FrequentlyQuestions";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function Register() {
  const theme = useTheme();
  // Variables para controlar el tamaño de la imagen
  const isAbove1000px = useMediaQuery("(min-width:1000px)");
  const isBelow1250px = useMediaQuery("(max-width:1250px)");
  const isBelow1628px = useMediaQuery("(max-width:1628px)");
  const isBelow833px = useMediaQuery("(max-width:833px)");

  // Variable para controlar el tamaño de la imagen en el media query md
  const mediaMd = useMediaQuery(theme.breakpoints.up("md"));

  //una alerta que diga "El usuario no existe, crea una cuenta"
  const handleRegister = () => {
    Swal.fire({
      title: "Error!",
      text: " ",
      icon: "error",
      confirmButtonText: "Cerrar",
    });
  };

  return (
    <>
      <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        <Grid
          container
          sx={{
            background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
            alignItems: "center",
            justifyContent: "center",
            flex:1
          }}
          px={{ xs: 2, md: 5 }}
          py={10}
          colomns={12}
        >
          <Grid
            size={{ md: 6 }}
            sx={{
              display: { xs: "none", md: "flex" },
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box mt={10} sx={{ width: { md: 160, lg: 180, xl: 200 } }}>
              <Image
                src={require("../assets/image/logo.png")}
                alt="Logo"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Box>

            <Box mt={-10} sx={{ width: { md: 400, lg: 450, xl: 518 } }}>
              <Image
                src={require("../assets/image/revision.png")}
                alt="Logo"
                style={{ width: "100%", objectFit: "contain" }}
              />
            </Box>

            <Typography component="Box" style={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  letterSpacing: -1,
                  fontSize: { md: "2.4rem", lg: "2.7rem", xl: "3.1rem" },
                }}
              >
                Comienza con nosotros
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  fontSize: { md: "2.8rem", lg: "3.4rem", xl: "3.9rem" },
                  letterSpacing: -1,
                  fontWeight: "bold",
                }}
                mt={1}
              >
                ¡Hora de registrarte!
              </Typography>
            </Typography>
          </Grid>
          <Grid
            size={{ md: 6 }}
            height={"100%"}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              component="Box"
              style={{ textAlign: "center" }}
              sx={{ display: { xs: "block", md: "none" } }}
              pt={10}
            >
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  letterSpacing: -1,
                  fontSize: { xs: "2rem", sm: "2.4rem" },
                }}
              >
                Comienza con nosotros
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  lineHeight: 0.9,
                  fontSize: { xs: "2.4rem", sm: "2.8rem" },
                  letterSpacing: -1,
                  fontWeight: "bold",
                }}
                mt={1}
              >
                ¡Hora de registrarte!
              </Typography>
            </Typography>
            <Box
              sx={{
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: 5,
                width: { md: isAbove1000px ? 435 : 369, lg: 500, xl: 660 },
                padding: "0 42px 36px 42px",
              }}
              mt={10}
              mx={{ sm: 4, md: "initial" }}
            >
              <Box
                sx={{
                  backgroundColor: "#11ddb3",
                  color: "white",
                  borderRadius: 2,
                  width: {
                    xs: 269,
                    sm: 400,
                    md: isAbove1000px ? 319 : 250,
                    lg: 350,
                    xl: 521,
                  },
                  height: 80,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  marginTop: "-39px",
                }}
              >
                <Typography
                  sx={{
                    margin: 0,
                    fontWeight: "bold",
                    fontSize: { xs: "1.8rem", sm: "2rem", xl: "2.5rem" },
                    letterSpacing: -1,
                  }}
                >
                  Regístrate
                </Typography>
              </Box>
              <Box>
                <Row className="mt-5 mb-3">
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Nombres" />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Apellidos" />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Correo electronico"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 style={{ marginTop: 20, textAlign: "left" }}>
                      Estilo preferido de paquetes
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      margin: "20px 0",
                    }}
                  >
                    <Form.Check
                      type="radio"
                      label="Sencillo"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios1"
                    />
                    <Form.Check
                      type="radio"
                      label="Mediano"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios2"
                    />
                    <Form.Check
                      type="radio"
                      label="Grande"
                      name="formHorizontalRadios"
                      id="formHorizontalRadios3"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h6 style={{ marginTop: 20, textAlign: "left" }}>
                      Cuéntanos sobre tu empresa o el servicio que deseas
                    </h6>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={3}
                        style={{ resize: "none" }}
                        placeholder="Escribe aquí"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <button
                  className="btn"
                  style={{
                    backgroundColor: "#1555fd",
                    color: "white",
                    borderRadius: 8,
                    fontWeight: "bold",
                    width: isBelow833px ? 150 : 230,
                    marginTop: 20,
                    padding: "12px 0",
                    fontSize: 20,
                  }}
                  onClick={() => handleRegister()}
                >
                  Enviar
                </button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
