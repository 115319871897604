import React from "react";
import { Image } from "react-bootstrap";
import {
  Card,
  CardContent,
  Box,
  Typography,
  CardActions,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FrequentlyQuestions from "../components/FrequentlyQuestions";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

function Subscriptions() {
  const theme = useTheme();
  // Variables para controlar el tamaño de la imagen
  const isAbove1000px = useMediaQuery("(min-width:1000px)");
  const isBelow1250px = useMediaQuery("(max-width:1250px)");
  const isBelow1628px = useMediaQuery("(max-width:1628px)");
  const isBelow833px = useMediaQuery("(max-width:833px)");

  // Variable para controlar el tamaño de la imagen en el media query md
  const mediaMd = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <>
      <Box sx={{ backgroundColor: "#DDDDDD" }}>
        <Grid
          container
          sx={{
            background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
            height: {
              xs: isBelow833px ? 900 : 555,
              md: isAbove1000px ? 700 : 635,
              lg: 800,
              xl: 1130,
            },
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
            width: "100%",
            alignItems: "center",
          }}
          px={{ xs: 2, sm: isBelow833px ? 3 : 2, md: 5 }}
          colomns={12}
        >
          <Grid
            size={{ md: 5 }}
            order={isBelow833px ? 2 : 1}
            sx={{
              height: { xs: isBelow833px ? "100%" : "inital" },
              margin: { xs: isBelow833px ? "35px auto " : 0 },
            }}
          >
            <Image
              src={require("../assets/image/portadaSubs.png")}
              alt="Portada Subscriptions"
              layout="fill"
              style={{
                width: mediaMd ? "100%" : "319px",
                height: mediaMd ? 690 : "319px",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid
            size={{ md: 7 }}
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              margin: {
                xs: "90px auto 0 auto",
                sm: isBelow833px ? "90px auto 0 auto" : "initial",
              },
            }}
            mt={{ xs: isBelow833px ? "90px" : 0, md: 0, xl: -24 }}
            order={isBelow833px ? 1 : 2}
          >
            <Box mt={{ sm: isBelow833px ? 0 : -8, lg: -10 }}>
              <Image
                src={require("../assets/image/logo.png")}
                alt="Logo"
                layout="fill"
                style={{
                  width: mediaMd ? "48%" : "280px",
                  objectFit: "contain",
                }}
              />
            </Box>
            <Typography component="div" mt={{ xs: -6, lg: -10 }}>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: {
                    xs: "2rem",
                    sm: isAbove1000px ? "2.9rem" : "2.6rem",
                    lg: isBelow1250px ? "3.45rem" : "3.60rem",
                    xl: isBelow1628px ? "4.4rem" : "4.75rem",
                  },
                  fontWeight: "700",
                  lineHeight: 0.9,
                  letterSpacing: -1,
                }}
              >
                Cotiza nuestros servicios
              </Typography>
              <Typography
                sx={{
                  fontStyle: "normal",
                  fontSize: {
                    xs: "1.5rem",
                    sm: isAbove1000px ? "2.2rem" : "1.9rem",
                    lg: "2.6rem",
                    xl: "3.45rem",
                  },
                  fontWeight: "500",
                  lineHeight: 0.9,
                  letterSpacing: -1,
                }}
              >
                Con una asesora personalizada
              </Typography>
            </Typography>
            <Box mt={{ xs: 3, lg: 8 }} sx={{ height: { xs: 67, lg: 120 } }}>
              <Link
                to="/register"
                className="btnNavBar btnNavBarSubs btn btn-primary"
              >
                <Typography
                  sx={{
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.8rem",
                      lg: "2.4rem",
                      xl: "3.4rem",
                    },
                  }}
                >
                  ¡Escríbenos ya!
                </Typography>
              </Link>
            </Box>
          </Grid>
        </Grid>

        <Card
          sx={{
            backgroundColor: "white",
            width: "94%",
            margin: "0 auto",
            marginTop: { xs: isBelow833px ? -5 : -10, xl: -25 },
            borderRadius: 2.8,
          }}
        >
          <CardContent
            sx={{
              backgroundColor: "white",
              margin: "0 auto",
            }}
            style={{ margin: "77px 0" }}
          >
            <Typography
              component="div"
              sx={{
                width: { xs: "initial", sm: 600 },
                margin: "0 auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <h2
                style={{
                  color: "#1555fd",
                  fontWeight: "bold",
                  width: "fit-content",
                  borderBottom: "3.5px solid #296ce0",
                  paddingBottom: 5,
                }}
              >
                Suscripciones
              </h2>
            </Typography>
            <Grid
              container
              sx={{
                marginTop: "69px",
                height: "auto",
                padding: { md: "0 90px", lg: "0px", xl: "0 40px" },
              }}
              columns={12}
            >
              <Grid
                item
                size={{ xs: 12, md: 6, lg: 3 }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 562,
                }}
                px={0.4}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    boxShadow: "none !important",
                  }}
                >
                  <CardContent
                    className="card-subscription"
                    sx={{
                      borderRadius: "23px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "30px 0",
                    }}
                  >
                    <Typography component="div">
                      <Box
                        style={{
                          textAlign: "center",
                          color: "#006BF1",
                          fontWeight: "bold",
                          fontSize: 55,
                        }}
                        className="card-subscriptionText"
                      >
                        50
                      </Box>
                      <Box
                        style={{
                          textAlign: "center",
                          fontSize: 21,
                          color: "#797979",
                        }}
                        className="card-subscriptionText"
                        mt={-2.5}
                      >
                        Envíos mensuales
                      </Box>
                    </Typography>
                    <Typography component="div">
                      <Box
                        style={{
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        <ul style={{ marginBottom: 0 }}>
                          <li>Asesoría y atención 24 horas</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>20 domiciliarios a tu disposición</li>
                        </ul>
                      </Box>
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: "center", display: "flex" }}
                    >
                      <Link
                        to="/register"
                        className="btnNavBar btn btn-primary btnSubs"
                      >
                        Lo quiero!
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 6, lg: 3 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 562,
                }}
                mt={{ xs: 4, md: 0, lg: 0 }}
                px={0.4}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    boxShadow: "none !important",
                  }}
                >
                  <CardContent
                    className="card-subscription"
                    sx={{
                      backgroundColor: "#006BF1",
                      borderRadius: "23px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "30px 0",
                    }}
                  >
                    <Typography component="div">
                      <Box
                        style={{
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 55,
                        }}
                        className="card-subscriptionTitle"
                      >
                        150
                      </Box>
                      <Box
                        style={{
                          textAlign: "center",
                          fontSize: 21,
                          color: "white",
                        }}
                        mt={-2.5}
                      >
                        Envíos mensuales
                      </Box>
                    </Typography>
                    <Typography component="div">
                      <Box
                        style={{
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: "600",
                          color: "white",
                        }}
                      >
                        <ul style={{ marginBottom: 0 }}>
                          <li>Asesoría y atención 24 horas</li>
                        </ul>
                        <hr
                          style={{
                            color: "white",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>50 domiciliarios a tu disposición</li>
                        </ul>
                        <hr
                          style={{
                            color: "white",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>Recaudo de pedidos</li>
                        </ul>
                      </Box>
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: "center", display: "flex" }}
                    >
                      <Link
                        to="/register"
                        className="btnNavBar btn btn-primary btnSubs"
                      >
                        Lo quiero!
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 6, lg: 3 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 562,
                }}
                mt={{ xs: 4, lg: 0 }}
                px={0.4}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    boxShadow: "none !important",
                  }}
                >
                  <CardContent
                    className="card-subscription"
                    sx={{
                      borderRadius: "23px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "30px 0",
                    }}
                  >
                    <Typography component="div">
                      <Box
                        style={{
                          textAlign: "center",
                          color: "#006BF1",
                          fontWeight: "bold",
                          fontSize: 55,
                        }}
                        className="card-subscriptionText"
                      >
                        300
                      </Box>
                      <Box
                        style={{
                          textAlign: "center",
                          fontSize: 21,
                          color: "#797979",
                        }}
                        className="card-subscriptionText"
                        mt={-2.5}
                      >
                        Envíos mensuales
                      </Box>
                    </Typography>
                    <Typography component="div">
                      <Box
                        style={{
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        <ul style={{ marginBottom: 0 }}>
                          <li>Asesoría y atención 24 horas</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>80 domiciliarios a tu disposición</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>Recaudo de pedidos</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>Garantía estado de paquetes</li>
                        </ul>
                      </Box>
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: "center", display: "flex" }}
                    >
                      <Link
                        to="/register"
                        className="btnNavBar btn btn-primary btnSubs"
                      >
                        Lo quiero!
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                size={{ xs: 12, md: 6, lg: 3 }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                  height: 562,
                }}
                mt={{ xs: 4, lg: 0 }}
                px={0.4}
              >
                <Card
                  sx={{
                    width: "100%",
                    height: "100%",
                    boxShadow: "none !important",
                  }}
                >
                  <CardContent
                    className="card-subscription"
                    sx={{
                      borderRadius: "23px",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "30px 0",
                    }}
                  >
                    <Typography component="div">
                      <Box
                        style={{
                          textAlign: "center",
                          color: "#006BF1",
                          fontWeight: "bold",
                          fontSize: 55,
                        }}
                        className="card-subscriptionText"
                      >
                        Plan
                      </Box>
                      <Box
                        style={{
                          textAlign: "center",
                          fontSize: 21,
                          color: "#797979",
                        }}
                        className="card-subscriptionText"
                        mt={-2.5}
                      >
                        Personalizado
                      </Box>
                    </Typography>
                    <Typography component="div">
                      <Box
                        style={{
                          fontSize: 16,
                          textAlign: "left",
                          fontWeight: "600",
                        }}
                      >
                        <ul style={{ marginBottom: 0 }}>
                          <li>Asesoría y atención 24 horas</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>80 domiciliarios a tu disposición</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>Recaudo de pedidos</li>
                        </ul>
                        <hr
                          style={{
                            color: "#BDBDBD",
                            borderWidth: 3,
                            margin: "0.7rem 0",
                          }}
                        />
                        <ul style={{ marginBottom: 0 }}>
                          <li>Atención exclusiva</li>
                        </ul>
                      </Box>
                    </Typography>
                    <CardActions
                      sx={{ justifyContent: "center", display: "flex" }}
                    >
                      <Link
                        to="/register"
                        className="btnNavBar btn btn-primary btnSubs"
                      >
                        Lo quiero!
                      </Link>
                    </CardActions>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <FrequentlyQuestions />
      <Footer />
    </>
  );
}

export default Subscriptions;
