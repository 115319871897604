import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Usa BrowserRouter
import Home from './pages/Home';
import OrganizationalCulture from './pages/OrganizationalCulture';
import Subscriptions from './pages/Subscriptions';
import NavBar from './components/NavBar';
import WhoWeAre from './pages/WhoWeAre';
import PayOrder from './pages/PayOrder';
import Login from './pages/Login';
import Register from './pages/Register';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/organizational-culture" element={<OrganizationalCulture />} />
        <Route path="/who-we-are" element={<WhoWeAre />} />
        <Route path="/pay-order" element={<PayOrder />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </Router>
  );
}

export default App;
