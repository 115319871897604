import React from "react";
import { Col, Row } from "react-bootstrap";

export default function FrequentlyQuestions() {
  return (
    <div style={{ backgroundColor: "#DDDDDD", paddingTop: 80 }}>
      <Row className="justify-content-center">
        <h2
          style={{
            color: "#1555fd",
            fontWeight: "bold",
            width: "fit-content",
            borderBottom: "3.5px solid #296ce0",
            paddingBottom: 5,
          }}
        >
          Preguntas frecuentes
        </h2>
      </Row>
      <Row style={{ width: "80%", margin: "50px auto 0 auto" }}>
        <Col sm={6}>
          <ul>
            <li style={{ fontWeight: "bold", fontSize: 18 }}>
              Como puedo empezar mi suscripción?
            </li>
            <p style={{ margin: 0, fontSize: 18 }}>
              Regístrate y contáctanos por nuestro formulario y nuestro equipo
              de servicio al cliente se encargará de activar tu suscripción.
            </p>
          </ul>
        </Col>
        <Col sm={6}>
          <ul>
            <li style={{ fontWeight: "bold", fontSize: 18 }}>
              Cuantos domiciliarios tengo a disposición?
            </li>
            <p style={{ margin: 0, fontSize: 18 }}>
              Si eres empresa y cuentas con suscripción, dependiendo del plan
              que adquieras tendrás la cantidad de domiciliarios, si eres
              persona natural, a convenirse según el envío.
            </p>
          </ul>
        </Col>
      </Row>
      <Row id="twoText" style={{ width: "80%", margin: "20px auto 0 auto" }}>
        <Col sm={6}>
          <ul>
            <li style={{ fontWeight: "bold", fontSize: 18 }}>
              Qué medios de pago poseen?
            </li>
            <p style={{ margin: 0, fontSize: 18 }}>
              Ponemos a tu disposición un convenio con Bancolombia para realizar
              el pago de todos tus servicios.
            </p>
          </ul>
        </Col>
        <Col sm={6}>
          <ul>
            <li style={{ fontWeight: "bold", fontSize: 18 }}>
              Puedo realizar envíos si soy persona natural?
            </li>
            <p style={{ margin: 0, fontSize: 18 }}>
              Si!, puedes contactarnos y realizar envíos sin necesidad de
              adquirir una suscripción mensual.
            </p>
          </ul>
        </Col>
      </Row>
    </div>
  );
}
