import React from "react";
import Footer from "../components/Footer";
import { Box } from "@mui/material";

export default function OrganizationalCulture() {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}>
      <Box sx={{ flex: "1", backgroundColor: "#DDDDDD" }} pt={20}>
        <Box
          id="organizationCulture"
          sx={{
            width: { xs: "75%", md: "40%" },
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 50,
            }}
          >
            <h3
              style={{
                color: "#1555fd",
                fontWeight: "bold",
                width: "fit-content",
                borderBottom: "3.5px solid #296ce0",
                lineHeight: 1,
              }}
            >
              CULTURA ORGANIZACIONAL
            </h3>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <h3
              style={{
                color: "#1555fd",
                fontWeight: "bold",
                width: "fit-content",
                borderBottom: "3.5px solid #296ce0",
                lineHeight: 1,
              }}
            >
              MISIÓN
            </h3>
          </div>
          <p style={{ margin: 0, fontSize: 18, textAlign: "justify" }}>
            Nuestra misión es ofrecer servicios de mensajería accesibles,
            eficientes y confiables para pequeñas empresas y personas en todo el
            país. A través de plataformas virtuales, brindamos una experiencia
            sencilla y ágil que permite a nuestros usuarios gestionar envíos de
            manera segura y sin complicaciones.
            <br />
            <br />
            Nos esforzamos por ofrecer soluciones inclusivas, conectando
            comunidades y fomentando el acceso a la tecnología, para que todos
            puedan participar en una economía digital más equitativa.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "60px 0 20px 0",
            }}
          >
            <h3
              style={{
                color: "#1555fd",
                fontWeight: "bold",
                width: "fit-content",
                borderBottom: "3.5px solid #296ce0",
                lineHeight: 1,
              }}
            >
              VISIÓN
            </h3>
          </div>
          <p style={{ margin: 0, fontSize: 18, textAlign: "justify" }}>
            Ser la empresa de mensajería líder en Colombia, ofreciendo un
            servicio integral que combina innovación tecnológica y logística
            eficiente.
            <br />
            <br />
            Aspiramos a ser el aliado confiable de pequeñas empresas, brindando
            herramientas financieras accesibles y un servicio de envío rápido y
            seguro, contribuyendo al crecimiento y desarrollo económico de
            nuestros clientes y del país.
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "60px 0 20px 0",
            }}
          >
            <h3
              style={{
                color: "#1555fd",
                fontWeight: "bold",
                width: "fit-content",
                borderBottom: "3.5px solid #296ce0",
                lineHeight: 1,
              }}
            >
              VALORES
            </h3>
          </div>
          <p style={{ margin: 0, fontSize: 18, textAlign: "justify" }}>
            Accesibilidad, innovación, inclusión, compromiso, sostenibilidad,
            conectividad.
          </p>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Footer />
      </Box>
    </Box>
  );
}
