import React, { useState } from "react";
import { Form, Image, InputGroup } from "react-bootstrap";
import letterSVG from "../assets/image/letter.svg";
import Lock from "../assets/image/lock.svg";
import Swal from "sweetalert2";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Login() {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");

  //una alerta que diga "El usuario no existe, crea una cuenta"
  const handleLogin = async () => {
    Swal.fire({
      title: "Error!",
      text: `El usuario ${user} no existe, crea una cuenta`,
      icon: "error",
      confirmButtonText: "Cerrar",
    }).then(() => {
      window.location.href = "/register";
    });
  };

  const isBelow833px = useMediaQuery("(max-width:833px)");

  return (
    <Box
      sx={{
        background: "linear-gradient(180deg, #0153FE 0%, #02E1B0 100%)",
        height: "100vh",
      }}
      style={{
        padding: "90px 20px 0 20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        id="principalContainer"
        sx={{
          position: "relative",
          // top: "50%",
          width: { xs: "100%", md: 650 },
          // transform: "translateY(-50%)",
          textAlign: "center",
          margin: "0 auto",
          padding: { xs: "40px 28px", sm: "40px 80px" },
          backgroundColor: "white",
          borderRadius: 5,
        }}
      >
        <Box
          id="title"
          sx={{
            height: 59,
            width: { xs: 205, sm: 320 },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#11ddb3",
            color: "white",
            borderRadius: 2,
            margin: "0 auto",
            position: "absolute",
            top: "0",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <h4 style={{ margin: 0, fontWeight: "bold" }}>Inicio de sesión</h4>
        </Box>
        <Box id="body"  mt={2.5}>
          <svg
            style={{
              width: 90,
              height: 90,
              fill: "#1555fd",
              margin: "20px auto",
            }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z" />
          </svg>
          <InputGroup className="mb-3">
            <InputGroup.Text id="correo">
              <Image
                src={letterSVG}
                alt="logo"
                style={{ width: 30, height: 30 }}
              />
            </InputGroup.Text>
            <Form.Control
              placeholder="Correo electronico"
              aria-label="Correo electronico"
              aria-describedby="correo"
              onChange={(e) => setUser(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="contraseña">
              <Image src={Lock} alt="logo" style={{ width: 30, height: 30 }} />
            </InputGroup.Text>
            <Form.Control
              placeholder="Contraseña"
              aria-label="Contraseña"
              aria-describedby="contraseña"
              onChange={(e) => setPassword(e.target.value)}
              type="password"
            />
          </InputGroup>
          <button
            className="btn"
            style={{
              backgroundColor: "#1555fd",
              color: "white",
              borderRadius: 8,
              fontWeight: "bold",
              width: isBelow833px ? 150 : 230,
              marginTop: 20,
              padding: "12px 0",
              fontSize: 20,
            }}
            disabled={user === "" || password === ""}
            onClick={() => handleLogin()}
          >
            INGRESAR
          </button>
        </Box>
      </Box>
    </Box>
  );
}
